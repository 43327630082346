<template>
  <div class="header-wrapper">
    <div class="base-box" :class="{ 'base-box-fixed': isFixed }" ref="baseSelf">
      <div class="top-title">
        <div class="top-title-item">
          <div class="logo">
            <img src="~@/assets/img/uia/logo-black.png" alt="" />
          </div>
          <div class="menu-list">
            <div v-for="item in menuList" :key="item.path" class="menu-item" :class="{ active: currentMenu == item.path }" @click="menuClick(item)">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="top-title-item" id="user-info-box">
          <div class="vip-container" v-if="userInfo && (userInfo.id || userInfo.userId) && vipInfo && vipInfo.memberLevel_dictText">
            会员等级：<span class="vip-text">{{ vipInfo.memberLevel_dictText }}</span>
          </div>
          <template v-if="userInfo">
            <el-dropdown :hide-on-click="true" trigger="click" placement="bottom"  @command="handleCommand">
              <span class="el-dropdown-link user-info-box">
                <img class="user-name" v-if="this.userInfo && this.userInfo.avatar" :src="userAvatar" alt="" />
                <img class="user-name" src="~@/assets/img/me/default-avatar.svg" v-else alt="" />
              </span>
              <el-dropdown-menu slot="dropdown" style="min-width: 120px">
                <div class="drop-list">
                  <el-dropdown-item @click="handleMyFree" command="/payment/MyFreeCard">我的免费卡</el-dropdown-item>
                  <el-dropdown-item @click="handleMyVip" command="/payment/MyVipCard">我的会员卡</el-dropdown-item>
                  <el-dropdown-item @click="handleMyCash" command="/payment/MyCashback">我的返现</el-dropdown-item>
                  <el-dropdown-item  @click="handleMyCoupon" command="/payment/MyCoupon">我的优惠券</el-dropdown-item>
                  <el-dropdown-item  @click="handleAccountInfo" command="me">我的账号</el-dropdown-item>
                  <el-dropdown-item @click="handleExit" command="logout">退出登录</el-dropdown-item>
<!--                  <div class="list-item" @click="handleMyCash">-->
<!--                    <div class="img"></div>-->
<!--                    <div class="text">我的返现</div>-->
<!--                  </div>-->
<!--                  <div class="list-item" @click="handleMyCoupon">-->
<!--                    <div class="img"></div>-->
<!--                    <div class="text">我的优惠券</div>-->
<!--                  </div>-->
<!--                  <div class="list-item" @click="handleAccountInfo">-->
<!--                    <div class="img"></div>-->
<!--                    <div class="text">我的账号</div>-->
<!--                  </div>-->
<!--                  <div class="list-item" @click="handleExit">-->
<!--                    <div class="img"></div>-->
<!--                    <div class="text">退出登录</div>-->
<!--                  </div>-->
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template v-else>
            <div class="operate-list">
              <div class="item" @click="handleLogin" style="color: #3399fe;background: #eaf4fe;">登录</div>
              <div class="item" @click="handleRegister">注册</div>

            </div>
          </template>
        </div>
      </div>
    </div>
    <login-register-modal ref="loginRegister" :visible.sync="loginRegisterVisible" @ok="loginSuccess" :modal="true" />
  </div>

</template>

<script>
import { getToken, setToken ,removeToken} from "@/utils/token";
import { mapGetters } from "vuex";
import InfoBubble from "./InfoBubble.vue";
import LoginRegisterModal from "@/components/login/LoginRegisterModal.vue";

export default {
  name: "Header",
  props: {},
  components: {
    InfoBubble,
    LoginRegisterModal
  },
  data() {
    return {
      loginRegisterVisible: false,

      showBubbleFlag: false,
      // 当前菜单，默认为 home
      currentMenu: "home",
      menuList: [
        {
          name: "首页",
          path: "/",
          // path: "/home",
        },
        {
          name: "我的订单",
          path: "/paper",
          // 提示需要登录
          auth: true,
        },
        {
          name: "模板设置",
          path: "/template",
          auth: true,
        },
      ],
      registerVisible: false, //账号注册
      loginVisible: false, //账号登录
      retrieveVisible: false, //找回密码
      isFixed: false,
      timer: null,
      loginType: "",
      // 图片预览地址
      previewUrl: window._CONFIG["domainURL"] + "/identity/sys/view/",
    };
  },
  watch: {
    // 监听路由变化，如果带有code
    // 监听 $route 对象变化，deep监听
    $route: {
      handler(to, from) {
        console.log("to");
        console.log(to);
        console.log("from");
        console.log(from);
        if (to.query.code) {
          console.log("code");
        }
        // 监听当前路由改变
        let object = this.menuList.find((item) => item.path == to.path);
        if (object) {
          this.currentMenu = object.path;
        } else {
          this.currentMenu = "";
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    // 是否登录，根据 token 判断
    userName() {
      return this.userInfo && this.userInfo.realname ? this.userInfo.realname.split("")[0] : "";
    },
    userAvatar() {
      if (this.userInfo && this.userInfo.avatar) {
        return this.previewUrl + this.userInfo.avatar;
      }
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    vipInfo() {
      return this.$store.state.vip.vipInfo;
    },
  },
  methods: {
    handleCommand(key) {
      if(key == "/payment/MyFreeCard"){
        this.handleMyFree()
      }
      if(key == "/payment/MyVipCard"){
        this.handleMyVip()
      }
      if(key == "/payment/MyCashback"){
        this.handleMyCash()
      }
      if(key == "/payment/MyCoupon"){
        this.handleMyCoupon()
      }
      if(key == "me"){
        this.handleAccountInfo()
      }
      if(key == "logout"){
        this.handleExit()
      }
    },
    loginSuccess() {
    },

    // 登录
    handleLogin() {
      //弹窗
      //this.loginRegisterVisible = true;
      //跳转
       this.$router.push({ path: "/user/login" });
    },
    handleRegister() {
      //弹窗
      // this.$refs.loginRegister.currentPage = "register"
      // this.loginRegisterVisible = true;
       //跳转
       this.$router.push({ path: "/user/register" });
    },
    // 我的免费卡
    handleMyFree() {
      this.$router.push({path: "/payment/MyFreeCard"}).catch((err) => {
      });
    },
    // 我的会员卡
    handleMyVip() {
      this.$router.push({path: "/payment/MyVipCard"}).catch((err) => {
      });
    },
    // 我的返现
    handleMyCash() {
      this.$router.push({path: "/payment/MyCashback"}).catch((err) => {
      });
    },
    // 我的优惠券
    handleMyCoupon() {
      this.$router.push({ path: "/payment/MyCoupon" }).catch((err) => {});
    },
    // 账号信息
    handleAccountInfo() {
      this.$router.push({ name: "me" }).catch((err) => {});
    },
    handleExit() {
      this.logout();
      // 退出登录
      // this.$confirm("确认退出？", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //   })
      //   .catch(() => {});
    },
    // 退出登录，清除 token
    async logout() {
      try {
        await this.$api.logout();
      } catch (error) {
        console.log(error);
      }
      this.$store.dispatch("setUserInfo", null);
      // this.$router.replace("/home");
      this.$router.replace("/");
      setToken("");
      // location.reload();
    },
    showBubbleFunc() {
      this.showBubbleFlag = true;
    },
    hideBubbleFunc() {
      this.showBubbleFlag = false;
    },
    // 菜单点击事件
    async menuClick(item) {

      const { path, auth } = item;
      if(path!="/"){
        if (auth && !getToken()) {
          // 提示
          // this.$message.error("请先登录");
          // this.$router.push({ path: "/user/login" });
          this.loginRegisterVisible = true;
          return;
        }
        if(this.userInfo && (this.userInfo.id || this.userInfo.userId)){
        }else{
          removeToken();
          this.$message.error("登录失效，请重新登录");
          this.$router.push({ path: "/user/login" });
          return;
        }
      }
      if (path) {
        this.currentMenu = path;
        this.$router.push({ path });
      }
    },
    handleClickMe() {
      this.$router.push({ path: "/me" });
    },
    handleTest() {
      // 刷新当前页面，并且带一个参数
      console.log("test");
      let code = "123456";
      console.log(code);
      // this.$router.replace({ path: "/home", query: { code } });
      this.$router.replace({ path: "/", query: { code } });
    },
  },
  created() {
    if(getToken()){
      this.$store.dispatch("getVipList");
    }
  },
  mounted() {},
  breforeDestroy() {
    // 组件销毁时，清除定时器
    clearTimeout(this.timer);
    // 移除滚动事件
    window.removeEventListener("scroll", () => {});
  },
};
</script>
<style lang="scss" scoped>
.header-wrapper {
  text-align: center;
}
.pointer {
  cursor: pointer;
}
.vip-container {
  margin-right: 24px;
}
.vip-text {
  color: #ffc107;
}

.drop-list {
  .list-item {
    list-style: none;
    line-height: 36px;
    padding: 0 20px;
    margin: 0;
    font-size: 14px;
    color: #606266;
    cursor: pointer;
    outline: none;
    &:hover {
      background-color: #ebf5ff;
      color: #3399ff;
    }
  }
}

// 顶部样式
.top-title {
  height: 60px;
  padding: 0 15px;
  color: #404656;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 5px 15px 5px #e5edf8;
  position: relative;
  z-index: 1;
  .top-title-item {
    display: flex;
    align-items: center;
    .operate-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      .operate-logo {
        position: relative;
        width: 15px;
        height: 15px;
        margin-right: 6px;
        transition: all 0.3s ease-in-out;
        img {
          position: absolute;
          left: 0;
          width: 100%;
        }
        .default-img {
          opacity: 1;
        }
        .focus-img {
          opacity: 0;
        }
      }
      &:hover {
        .operate-logo {
          .default-img {
            opacity: 0;
          }
          .focus-img {
            opacity: 1;
          }
        }
      }
      &:hover {
        color: #3399fe;
      }
    }
    .person-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      min-width: 150px;
      position: relative;
      height: 50px;
      margin-left: 12px;
      background: #3399fe;
      color: #fff;
      text-align: center;
      cursor: pointer;
      .person-logo {
        width: 15px;
        height: 15px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
  }
  .logo {
    width: 210px;
    text-align: center;
    img {
      max-width: 120px;
    }
  }
  .menu-list {
    display: flex;
    font-size: 22px;
    justify-content: flex-start;
    .menu-item {
      position: relative;
      font-size: 16px;
      line-height: 20px;
      display: block;
      padding: 20px 12px;
      color: #404656;
      text-decoration: none;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        color: #3399fe;
      }
    }
    .active {
      color: #3399fe;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 8px solid#fff;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .input-box {
    position: relative;
    height: 40px;
    width: 300px;
    border: 1px solid;
    border-radius: 16px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    .input-btn {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-radius: 50%;
      margin-top: 4px;
      cursor: pointer;
      img {
        width: 100%;
        margin: 0 auto;
      }
    }
    input {
      width: 280px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-left: 20px;
      font-size: 20px;
    }
    .my_input {
      font-size: 15px;
      height: 30px;
      vertical-align: top;
      outline: 0;
      box-shadow: none;
      border-radius: 10px;
      border: none;
      background: #fff;
      color: #222;
      overflow: hidden;
      box-sizing: content-box;
      -webkit-tap-highlight-color: transparent;
    }
    .my_input.focus {
      border-color: #4e6ef2 !important;
      opacity: 1;
    }
  }
  .icon-box {
    width: 28px;
    height: 28px;
    margin: 0 10px;
    cursor: pointer;
    -webkit-transition: all 1s ease 0.1s;
    -o-transition: all 1s ease 0.1s;
    -moz-transition: all 1s ease 0.1s;
    transition: all 1s ease 0.1s;
    transform: scale(0.9);
    &:hover {
      transform: scale(1);
      animation: breathe 1s linear infinite 2s;
    }
    img {
      width: 100%;
    }
  }
}
.base-box-fixed {
  position: fixed;
  box-shadow: 0 6px 12px 0rgb (0 0 0 / 5%);
}
.user-info-box {
  //position: relative;
  width: 50px;
  .user-name {
    margin-right: 6px;
    color: #fff;
    // border: 2px solid #fff;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    font-weight: 500;
    // border-radius: 100%;
    cursor: pointer;
    // box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.3);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.info-bubble {
  position: absolute;
  display: block;
  width: 48px;
  min-height: 48px;
  top: 0;
  right: 0;
}
.operate-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 14px;
  .item {
    width: 68px;
    border-radius: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 2px;
    padding: 2px 0;
    cursor: pointer;
    //&:hover {
    //  color: #3399fe;
    //  background: #eaf4fe;
    //}
  }
}
// 使用 @media 适应小屏的样式
@media screen and (max-width: 1550px) {
  // 顶部样式
  .top-title {
    .top-title-item {
    }
    .logo {
      width: 210px;
      img {
      }
    }
    .menu-list {
      .menu-item {
      }
    }
    .input-box {
      width: 300px;
      .input-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 50%;
        margin-top: 4px;
        img {
          width: 100%;
          margin: 0 auto;
        }
      }
      input {
        width: 200px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-left: 20px;
      }
      .my_input {
      }
      .my_input.focus {
      }
    }
    .icon-box {
      img {
      }
    }
  }
}
</style>
